<template>
  <div class="pushNotice">
    <div class="navC">{{ id ? "修改公告" : "发布公告" }}</div>
    <!-- 表单内容 -->
    <div class="formBox">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="公告标题" prop="title">
          <el-input v-model="ruleForm.title" style="width: 350px" clearable></el-input>
        </el-form-item>

        <el-form-item label="公告内容" prop="content">
          <div class="pr80">
            <Editor v-model="ruleForm.content"></Editor>
          </div>
        </el-form-item>

        <!-- <el-form-item label="公告图片">
          <div class="upImg">
            <dc-upload class="avatar-uploader" ref="upload" :dcAutoUpload="true" :show-file-list="false" dcdom="ceshi" :dcConfig="dcConfig"
              @dc-success="dcSuccess" @dc-progress="dcProgressTrue = false" v-if="isUpOk"
              accept=".jpg,.png,.swf,.bmp,.jpeg,.JPG,.PNG,.SWF,.BMP,.JPEG">
              <div v-if="cover_url" class="avatar">
                <img :src="cover_url" />
              </div>
              <div v-else class="jh">
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </div>
            </dc-upload>
          </div>
        </el-form-item> -->
        <el-form-item label="上传附件">
          <div class="upImg">
            <!-- 金山云组件上传图片 -->
            <dc-upload class="avatar-uploader" ref="upload" :dcAutoUpload="true" :show-file-list="false" dcdom="ceshi1" :dcConfig="dcConfig"
              @dc-success="dcSuccess1" @dc-progress="dcProgressTrue = false" v-if="isUpOk1" accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf">
              <el-button type="primary">点击选择</el-button>
              <span style="margin-left: 20px">支持扩展名：.doc .docx .xls .xlsx .ppt .pptx .pdf</span>
            </dc-upload>
          </div>
          <!-- //上传的附件 uploadList-->
          <div class="uploadData">
            <div class="childBox" v-for="(item, index) in uploadList" :key="index">
              <div class="mBox">
                <div class="photo">
                  <img src="../../assets/navIcon/word.png" v-if="item.type == 'doc' || item.type == 'docx'" alt="" />
                  <img src="../../assets/navIcon/ppt.png" v-if="item.type == 'ppt' || item.type == 'pptx'" alt="" />
                  <img src="../../assets/navIcon/pdf.png" v-if="item.type == 'pdf'" alt="" />
                  <img src="../../assets/navIcon/excel.png" v-if="item.type == 'xls' || item.type == 'xlsx'" alt="" />
                </div>
                <div class="title">
                  <span :title="item.name">{{ item.name }}</span>
                </div>
              </div>

              <div class="rBox">
                <span @click="removieData(item)">X</span>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button @click="onCancel">取消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')" class="saveBtn" :loading="save_loading_btn">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { UpdateNotice } from "@/api/teacherStudio";
import { debounce, throttle } from "@/utils/utils.js";
export default {
  data() {
    return {
      save_loading_btn: false,
      //附件数组
      uploadList: [],
      isUpOk: false,
      isUpOk1: false,
      dcConfig: {
        key: "", // 后台域名对应key
        notify_url: "", // 上传接口地址
      },
      //封面图片url
      cover_url: "",
      //  表单信息
      ruleForm: {
        title: "",
        content: "",
        photo_id: "",
        resource: [],
        teaching_studio_id: localStorage.getItem("studioId"),
        user_id: localStorage.getItem("id"),
      },
      rules: {
        title: [
          { required: true, message: "请输入公告标题", trigger: "blur" },
          {
            min: 0,
            max: 64,
            message: "长度在 0 到 64 个字符",
            trigger: "blur",
          },
        ],
        content: [
          { required: true, message: "请填写公告内容", trigger: "change" },
        ],
      },
      id: "",
    };
  },
  created() {
    let rouName = this.$route.name
    this.$emit('rouname', rouName)
    // 初始化金山云地址
    this._getAddress();
    //判断是否需
    let nid = this.$route.query.nid;
    this.id = nid;
    if (nid) {
      this.getNotice(nid);
    }
  },
  computed: {},
  methods: {
    //获取信息
    getNotice(nid) {
      this.$axios.get("index/teachingStudioNotice/infoTeachingStudioNotice", {
        params: {
          id: nid,
        },
      }).then((res) => {
        let { title, content, photo_id, resource, photo_url, resource_file } = res.data.data;
        resource = resource.split(",");
        resource = resource.map((e) => Number(e));
        this.cover_url = photo_url;
        resource_file.map((e) => {
          let index = e.url.lastIndexOf(".");
          let ext = e.url.substring(index + 1);
          e.type = ext;
        });
        this.uploadList = resource_file;
        this.ruleForm = {
          title,
          content,
          photo_id,
          resource,
          teaching_studio_id: localStorage.getItem("studioId"),
          user_id: localStorage.getItem("id"),
        };
      });
    },
    onCancel() {
      this.$router.go(-1);
    },
    //移除资源
    removieData(e) {
      let index1 = this.uploadList.indexOf(e);
      let index2 = this.ruleForm.resource.indexOf(e.id);
      this.uploadList.splice(index1, 1);
      this.ruleForm.resource.splice(index2, 1);
    },
    submitForm: throttle(function (formName) {
      if (this.save_loading_btn) {
        return false;
      };
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.id) {
            // 修改
            this.ruleForm.id = this.id;
            this.save_loading_btn = true;
            UpdateNotice(this.ruleForm).then((res) => {
              this.save_loading_btn = false;
              this.$message.success("修改公告成功");
              this.$router.go(-1);
            }).catch((err) => {
              this.save_loading_btn = false;
            });
          } else {
            // 添加
            this.save_loading_btn = true;
            this.$axios.post("index/teachingStudioNotice/addTeachingStudioNotice", this.ruleForm).then(() => {
              this.save_loading_btn = false;
              this.$message.success("创建公告成功");
              this.$router.go(-1);
            }).catch((err) => {
              this.save_loading_btn = false;
            });
          }
        }
      });
    }),

    // 获取金山云配置
    async _getAddress() {
      let res = await this.$axios.get("/k3sSetting");
      console.log(res, "金山云配置");
      this.dcConfig.key = res.data.data.key;
      this.dcConfig.notify_url = res.data.data.callBack;
      this.isUpOk = true;
      this.isUpOk1 = true;
    },
    // 上传成功后的回调
    async dcSuccess(data) {
      console.log(data, "数据");
      if (!data.upload) return;
      // 得到上传文件的数据list
      let resData = data.upload.CompleteMultipartUploadResult;
      //去掉resData.ETag属性的双引号
      resData.ETag = resData.ETag.replace(/"/g, "");
      let etag = resData.ETag;
      // 上传成功就关掉
      this.isUpOk = false;
      //请求erg获取上传文件返回的etagid
      let res = await this.$axios.get("/etagChangeId", {
        params: {
          etag: etag,
        },
      });
      //把本地文件地址给cover_url变量
      this.cover_url = resData.Location;
      this.ruleForm.photo_id = res.data.data.id;
      this.isUpOk = true;
      // console.log(res,"res数据");
    },
    // 上传成功后的回调
    dcSuccess1(data) {
      console.log(data, "数据");
      if (!data.upload) return;
      // 得到上传文件的数据list
      let resData = data.upload.CompleteMultipartUploadResult;
      //去掉resData.ETag属性的双引号
      resData.ETag = resData.ETag.replace(/"/g, "");
      let etag = resData.ETag;
      // 上传成功就关掉
      //请求erg获取上传文件返回的etagid
      this.$axios.get("/etagChangeId", { params: { etag: etag }, }).then((res) => {
        this.ruleForm.resource.push(res.data.data.id);
        //数组
        this.uploadList.push({
          id: res.data.data.id,
          name: data.filename,
          type: data.filename.split(".")[1],
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.pushNotice {
  box-sizing: border-box;
  .navC {
    width: 100%;
    height: 60px;
    padding-left: 26px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid #f0f0f0;
  }
  .formBox {
    padding-top: 40px;
    box-sizing: border-box;
    padding-right: 20px;
    .upImg {
      margin-left: 10px;
      .avatar-uploader {
        .avatar {
          width: 120px;
          height: 120px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .jh {
          width: 134px;
          height: 75px;
          background: #f7f7f7;
          border: 1px solid #ececec;
          border-radius: 4px;
          box-sizing: border-box;

          .avatar-uploader-icon {
            font-size: 30px;
            margin-top: 23px;
          }
        }
      }
      .disabled .el-upload--picture-card {
        display: none;
      }
    }
    .uploadData {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      .childBox {
        width: 300px;
        height: 50px;
        background: #f9f9f9;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 20px;
        margin-bottom: 10px;
        .mBox {
          display: flex;
          align-items: center;
          .photo {
            width: 24px;
            height: 24px;
            margin-left: 12px;
            img {
              width: 24px;
              height: 24px;
            }
          }
          .title {
            margin-left: 10px;
            width: 153px;
            white-space: pre-line;
            overflow: hidden;
            -webkit-line-clamp: 1;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            cursor: pointer;
            span {
              font-size: 14px;
              font-weight: 400;
              text-align: left;
              color: #303133;
            }
          }
        }
        .rBox {
          margin-right: 20px;
          span {
            width: 7px;
            height: 7px;
            color: #9c9fa4;
            cursor: pointer;
            &:hover {
              color: #ff8201;
            }
          }
        }
      }
    }
    //保存
    .saveBtn {
      background: #ff8201;
      border: 1px solid #ff8201;
      border-radius: 6px;
    }
  }
}
</style>
